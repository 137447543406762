import { Component } from '@angular/core';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { Platform } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private oneSignal: OneSignal,private platform: Platform) {
     // Wait until platform is ready
     this.platform.ready().then(async () => {
      await this.startOneSignal();
      StatusBar.setStyle({ style: Style.Light });
      const backgroundColorOptions = {
        color: '#f4f5f8'
      };
      StatusBar.setBackgroundColor(backgroundColorOptions);

      if(platform.width() > 450) {
        alert('This app is optimized for mobile devices. Please, use a mobile device for the best experience. ');
        window.location.href = 'https://datpay.bz';
      }

    });
  }

  startOneSignal() {
    this.oneSignal.startInit('4fdb8582-c6fe-435c-87ad-32b823589124', '840181434490');

    this.oneSignal.handleNotificationOpened().subscribe(() => {
      // do something when a notification is opened
    });
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);

    this.oneSignal.handleNotificationReceived().subscribe(() => {
    // do something when notification is received
    });

    this.oneSignal.handleNotificationOpened().subscribe(() => {
      // do something when a notification is opened
    });

    this.oneSignal.endInit();
  }
}
