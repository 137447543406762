import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'boledo-list',
    loadChildren: () => import('./boledo-list/boledo-list.module').then( m => m.BoledoListPageModule)
  },
  {
    path: 'lottery-list',
    loadChildren: () => import('./lottery-list/lottery-list.module').then( m => m.LotteryListPageModule)
  },
  {
    path: 'fantasy-list',
    loadChildren: () => import('./fantasy-list/fantasy-list.module').then( m => m.FantasyListPageModule)
  },
  {
    path: 'pick-list',
    loadChildren: () => import('./pick-list/pick-list.module').then( m => m.PickListPageModule)
  },
  {
    path: 'rake-book',
    loadChildren: () => import('./rake-book/rake-book.module').then( m => m.RakeBookPageModule)
  },
  {
    path: 'tab4',
    loadChildren: () => import('./tab4/tab4.module').then( m => m.Tab4PageModule)
  },
  {
    path: 'buy-boledo',
    loadChildren: () => import('./buy-boledo/buy-boledo.module').then( m => m.BuyBoledoPageModule)
  },
  {
    path: 'buy-lottery',
    loadChildren: () => import('./buy-lottery/buy-lottery.module').then( m => m.BuyLotteryPageModule)
  },
  {
    path: 'buy-fantasy',
    loadChildren: () => import('./buy-fantasy/buy-fantasy.module').then( m => m.BuyFantasyPageModule)
  },
  {
    path: 'buy-pick',
    loadChildren: () => import('./buy-pick/buy-pick.module').then( m => m.BuyPickPageModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then( m => m.AuthPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./forget-password/forget-password.module').then( m => m.ForgetPasswordPageModule)
  },
  {
    path: 'receipt-profile',
    loadChildren: () => import('./receipt-profile/receipt-profile.module').then( m => m.ReceiptProfilePageModule)
  },
  {
    path: 'receipt-profile-lottery',
    loadChildren: () => import('./receipt-profile-lottery/receipt-profile-lottery.module').then( m => m.ReceiptProfileLotteryPageModule)
  },
  {
    path: 'receipt-profile-fantasy',
    loadChildren: () => import('./receipt-profile-fantasy/receipt-profile-fantasy.module').then( m => m.ReceiptProfileFantasyPageModule)
  },
  {
    path: 'receipt-profile-pick',
    loadChildren: () => import('./receipt-profile-pick/receipt-profile-pick.module').then( m => m.ReceiptProfilePickPageModule)
  },
  {
    path: 'info',
    loadChildren: () => import('./info/info.module').then( m => m.InfoPageModule)
  },
  {
    path: 'sunday-boledo',
    loadChildren: () => import('./sunday-boledo/sunday-boledo.module').then( m => m.SundayBoledoPageModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.module').then( m => m.ErrorPageModule)
  },
  {
    path: 'free-tickets',
    loadChildren: () => import('./free-tickets/free-tickets.module').then( m => m.FreeTicketsPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
